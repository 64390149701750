export const CURRENT_SEASON = '2023-24'
export const CURRENT_SEASON_ID = '00223'
export const CURRENT_PLAYOFF_SEASON_ID = '00423'
export const MOST_RECENT_DRAFT_YEAR = 2024
export const MOST_RECENT_DRAFT_SEASON = '2024-2025'

export const DEFAULT_PATH = '/team/40/overview'

// Salary Cap
export const CURRENT_BUDGET_SEASON = '2024-2025'
export const CURRENT_BUDGET_YEAR = 2024
