import { useNavigation, useSearchParams } from '@remix-run/react'
import { useSpinDelay } from 'spin-delay'
import { CURRENT_SEASON_ID } from '#app/utils/constants.ts'
import { type getSeasons } from '#app/utils/nba.server.ts'
import { TeamImage } from './team-image.tsx'
import { Combobox } from './ui/combobox.tsx'
import { Icon } from './ui/icon.tsx'

const SEASON_COMBOBOX_ID = 'szn_box'
export const SEASON_COMBOBOX_VALUE = `${SEASON_COMBOBOX_ID}_v`

export const getSeasonId = (search: URLSearchParams, defaultValue?: string) => {
	return search.get(SEASON_COMBOBOX_VALUE) || defaultValue || CURRENT_SEASON_ID
}

export const useSeasonId = (defaultValue?: string) => {
	const [search] = useSearchParams()
	return search.get(SEASON_COMBOBOX_VALUE) || defaultValue || CURRENT_SEASON_ID
}

type SeasonData = Awaited<ReturnType<typeof getSeasons>>[number]

interface Props<Data, Key> {
	options: Data[]
	className?: string
	id?: string
	valueKey?: Key
	defaultValue?: string
	onSearchChange?: (search: URLSearchParams) => URLSearchParams
}

export function SeasonCombobox<
	Data extends SeasonData,
	Key extends keyof Data,
>({
	options,
	className,
	id = SEASON_COMBOBOX_ID,
	valueKey,
	defaultValue,
	onSearchChange = search => search,
}: Props<Data, Key>) {
	const navigation = useNavigation()
	const [search, setSearch] = useSearchParams()

	const seasonId = useSeasonId(defaultValue)

	const handleSeasonSelect = (option: Data['season_id'] | Data[Key]) => {
		search.set(SEASON_COMBOBOX_VALUE, `${option}`)
		setSearch(onSearchChange(search), {
			state: { option },
			preventScrollReset: true,
			replace: true,
		})
	}

	const pending = useSpinDelay(
		navigation.state !== 'idle' &&
			navigation.location.state?.option === search.get(SEASON_COMBOBOX_VALUE),
		{
			delay: 400,
			minDuration: 300,
		},
	)

	return (
		<Combobox
			id={id}
			disabled={options.length <= 0}
			className={className}
			onSelect={handleSeasonSelect}
			options={options}
			value={seasonId}
			valueKey={valueKey || 'season_id'}
			variant="outline"
			renderLabel={o => (
				<>
					{o
						? valueKey
							? o[valueKey]
							: `${o.season} (${o.league} ${o.season_type})`
						: 'Select a Season'}
					{pending ? (
						<Icon className="ml-2 animate-spin" name="rotate-cw" />
					) : null}
				</>
			)}
		>
			{o =>
				valueKey
					? `${o[valueKey]}`
					: `${o.season} (${o.league} ${o.season_type})`
			}
		</Combobox>
	)
}

export function SeasonComboboxWithTeam<
	Data extends SeasonData,
	Key extends keyof Data,
>({
	options,
	className,
	id = SEASON_COMBOBOX_ID,
	defaultValue,
}: Props<Data, Key>) {
	const navigation = useNavigation()
	const [search, setSearch] = useSearchParams()

	const seasonId = useSeasonId(defaultValue)

	const handleSeasonSelect = (option: Data['season_id'] | Data[Key]) => {
		search.set(SEASON_COMBOBOX_VALUE, `${option}`)
		setSearch(search, {
			state: { option },
			preventScrollReset: true,
			replace: true,
		})
	}

	const pending = useSpinDelay(
		navigation.state !== 'idle' &&
			navigation.location.state?.option === search.get(SEASON_COMBOBOX_VALUE),
		{
			delay: 400,
			minDuration: 300,
		},
	)

	return (
		<Combobox
			id={id}
			className={className}
			onSelect={handleSeasonSelect}
			options={options}
			value={seasonId}
			valueKey={'season_id'}
			variant="outline"
			renderLabel={o => (
				<>
					{o ? `${o.team?.team_full_name} (${o.season})` : 'Select a Season'}
					{pending ? (
						<Icon className="ml-2 animate-spin" name="rotate-cw" />
					) : null}
				</>
			)}
		>
			{o => (
				<div className="flex items-center gap-4">
					{o.team ? <TeamImage size="sm" team={o.team} /> : null}
					<div className="flex-1">
						<p className="text-md font-medium">{o.team?.team_full_name}</p>
						<p className="text-small text-muted-foreground">
							{o.season} ({o.league})
						</p>
					</div>
				</div>
			)}
		</Combobox>
	)
}
