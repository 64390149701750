import { useState } from 'react'
import { cn } from '#app/utils/misc.tsx'
import { NBATeamIdSchema, teamsById } from '#app/utils/teams.ts'
import { Avatar, AvatarImage, AvatarFallback } from './ui/avatar.tsx'
import { Icon } from './ui/icon.tsx'

type Team = {
	team_full_name: string
	team_id: number | string
	team_id_nba: string | null
	logo_url: string | null
}

const sizes = {
	sm: '!h-8 !w-8',
	md: '!h-10 !w-10 md:!h-12 md:!w-12',
	lg: '!h-12 !w-12 md:!h-14 md:!w-14',
}

export function TeamImage<Data extends Team>({
	className,
	team,
	size = 'md',
}: {
	className?: string
	size?: 'sm' | 'md' | 'lg'
	team: Data
}) {
	const [source, setSource] = useState(
		team.team_id_nba
			? `https://cdn.nba.com/logos/nba/${team.team_id_nba}/primary/L/logo.svg`
			: team.logo_url || '',
	)

	function onLoadingStatusChange(
		status: 'idle' | 'loading' | 'loaded' | 'error',
	) {
		if (status === 'error' && team.logo_url) {
			setSource(team.logo_url)
		}
	}

	const nbaTeam = NBATeamIdSchema.safeParse(`${team.team_id}`)
	if (nbaTeam.success) {
		return (
			<Icon
				className={cn(
					'flex items-center justify-center ',
					sizes[size],
					className,
				)}
				name={teamsById[nbaTeam.data].icon}
			/>
		)
	}

	return (
		<Avatar className={cn('group rounded-none', sizes[size], className)}>
			<div className="opacity-0 transition-all group-has-[img]:opacity-100">
				<AvatarImage
					onLoadingStatusChange={onLoadingStatusChange}
					className="object-cover"
					src={source}
					alt={`${team.team_full_name} Headshot`}
				/>
			</div>
			<AvatarFallback delayMs={size === 'sm' || size === 'md' ? 100 : 200}>
				<Icon size={size} name="shield" />
			</AvatarFallback>
		</Avatar>
	)
}
