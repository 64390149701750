import { useInputControl } from '@conform-to/react'
import React, { useId } from 'react'
import { Checkbox, type CheckboxProps } from './ui/checkbox.tsx'
import { Input } from './ui/input.tsx'
import { Label } from './ui/label.tsx'
import { Textarea } from './ui/textarea.tsx'

export type ListOfErrors = Array<string | null | undefined> | null | undefined

export function ErrorList({
	id,
	errors,
}: {
	errors?: ListOfErrors
	id?: string
}) {
	const errorsToRender = errors?.filter(Boolean)
	if (!errorsToRender?.length) return null
	return (
		<ul id={id} className="mt-1 flex flex-col gap-1">
			{errorsToRender.map(e => (
				<li key={e} className="text-xs text-destructive">
					{e}
				</li>
			))}
		</ul>
	)
}

export function Field({
	labelProps,
	errors,
	className,
	...inputProps
}: {
	labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>
	errors?: ListOfErrors
	className?: string
} & React.InputHTMLAttributes<HTMLInputElement>) {
	const fallbackId = useId()
	const id = inputProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div className={className}>
			{labelProps ? <Label htmlFor={id} {...labelProps} /> : null}
			<Input
				autoComplete="off"
				autoCorrect="off"
				id={id}
				aria-invalid={errorId ? true : undefined}
				aria-describedby={errorId}
				{...inputProps}
			/>
			{errorId ? (
				<div className="pt-1">
					<ErrorList id={errorId} errors={errors} />{' '}
				</div>
			) : null}
		</div>
	)
}

export function TextareaField({
	labelProps,
	errors,
	className,
	...textareaProps
}: {
	labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>
	errors?: ListOfErrors
	className?: string
} & React.InputHTMLAttributes<HTMLTextAreaElement>) {
	const fallbackId = useId()
	const id = textareaProps.id ?? textareaProps.name ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div className={className}>
			{labelProps ? <Label htmlFor={id} {...labelProps} /> : null}
			<Textarea
				id={id}
				aria-invalid={errorId ? true : undefined}
				aria-describedby={errorId}
				{...textareaProps}
			/>
			{errorId ? (
				<div className="pt-1">
					<ErrorList id={errorId} errors={errors} />
				</div>
			) : null}
		</div>
	)
}

export function CheckboxField({
	labelProps,
	errors,
	className,
	...buttonProps
}: {
	labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>
	errors?: ListOfErrors
	className?: string
} & CheckboxProps & {
		name: string
		form: string
		value?: string
	}) {
	const { key, defaultChecked, ...checkboxProps } = buttonProps
	const fallbackId = useId()
	const checkedValue = buttonProps.value ?? 'on'
	const input = useInputControl({
		key,
		name: buttonProps.name,
		formId: buttonProps.form,
		initialValue: defaultChecked ? checkedValue : undefined,
	})
	const id = buttonProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined

	return (
		<div className={className}>
			<div className="flex items-center gap-2">
				<Checkbox
					{...checkboxProps}
					id={id}
					aria-invalid={errorId ? true : undefined}
					aria-describedby={errorId}
					checked={input.value === checkedValue}
					onCheckedChange={state => {
						input.change(state.valueOf() ? checkedValue : '')
						buttonProps.onCheckedChange?.(state)
					}}
					onFocus={event => {
						input.focus()
						buttonProps.onFocus?.(event)
					}}
					onBlur={event => {
						input.blur()
						buttonProps.onBlur?.(event)
					}}
					type="button"
				/>
				{labelProps ? <Label htmlFor={id} {...labelProps} /> : null}
			</div>
			{errorId ? (
				<div className="pt-1">
					<ErrorList id={errorId} errors={errors} />
				</div>
			) : null}
		</div>
	)
}
