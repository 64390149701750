import {
	useFormAction,
	useLocation,
	useNavigation,
	useSearchParams,
} from '@remix-run/react'
import { clsx, type ClassValue } from 'clsx'
import React, {
	type MutableRefObject,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import { type ModelStatic, type Attributes, type Model } from 'sequelize'
import { useSpinDelay } from 'spin-delay'
import { extendTailwindMerge } from 'tailwind-merge'
import { SEASON_COMBOBOX_VALUE } from '#app/components/season-combobox.tsx'
import { MOST_RECENT_DRAFT_YEAR } from './constants.ts'
import { extendedTheme } from './extended-theme.ts'
import { objectHasValues, mapKeys } from './orm.ts'

export const EMPTY_ARRAY = []

export function getErrorMessage(error: unknown) {
	if (typeof error === 'string') return error
	if (
		error &&
		typeof error === 'object' &&
		'message' in error &&
		typeof error.message === 'string'
	) {
		return error.message
	}
	console.error('Unable to get error message for error', error)
	return 'Unknown Error'
}

function formatColors() {
	const colors = []
	for (const [key, color] of Object.entries(extendedTheme.colors)) {
		if (typeof color === 'string') {
			colors.push(key)
		} else {
			const colorGroup = Object.keys(color).map(subKey =>
				subKey === 'DEFAULT' ? '' : subKey,
			)
			colors.push({ [key]: colorGroup })
		}
	}
	return colors
}

export function formatOrdinals(n: number) {
	const pr = new Intl.PluralRules('en-US', { type: 'ordinal' })
	const suffixes = new Map([
		['one', 'st'],
		['two', 'nd'],
		['few', 'rd'],
		['other', 'th'],
	])
	const rule = pr.select(n)
	const suffix = suffixes.get(rule)
	return `${n}${suffix}`
}

export function formatPercent(
	n?: number | null,
	options?: Intl.NumberFormatOptions,
) {
	if (n === undefined || n === null) {
		return '-'
	}

	return n.toLocaleString('en-US', {
		style: 'percent',
		minimumFractionDigits: 1,
		...options,
	})
}

export function formatNetPoints(n?: number | null) {
	const value = formatNumber(n, {
		signDisplay: 'always',
		minimumFractionDigits: 2,
	})

	if (value.startsWith('-')) {
		// VsCode might say, "The character U+2212 "−" could be confused with the ASCII character U+002d "-", which is more common in source code."
		// However we are replacing the minus sign here so that tabular nums will line up correctly with values that contain a + sign
		return value.replace('-', '−')
	}

	return value
}

export function formatNumber(
	n?: number | null,
	options?: Intl.NumberFormatOptions,
) {
	if (n === undefined || n === null) {
		return '-'
	}

	return new Intl.NumberFormat('en-US', {
		maximumFractionDigits: 2,
		...options,
	}).format(n)
}

export function formatUSD(
	n?: number | null,
	options?: Intl.NumberFormatOptions,
) {
	if (n === undefined || n === null) {
		return '0'
	}

	return new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		notation: 'compact',
		maximumFractionDigits: 1,
		...options,
	}).format(n)
}

export function formatMinutesPlayed(
	minutes?: number | null,
	{ format }: { format?: 'mm:ss' | 'hh:mm:ss' } = { format: 'mm:ss' },
) {
	if (minutes === undefined || minutes === null) {
		return '-'
	}

	const sign = minutes < 0 ? '-' : ''
	const hour = Math.floor(Math.abs(minutes) / 60)
	const min =
		format == 'hh:mm:ss'
			? Math.floor(Math.abs(minutes) % 60)
			: Math.floor(Math.abs(minutes))
	const sec = Math.floor((Math.abs(minutes) * 60) % 60)

	const hours = format == 'hh:mm:ss' ? [hour < 10 ? '0' : '', hour, ':'] : []

	return [sign, ...hours, min, ':', sec < 10 ? '0' : '', sec].join('')
}

// Some helpful Linear Equations
// https://www.trysmudford.com/blog/linear-interpolation-functions/
export const lerp = (x: number, y: number, a: number) => x * (1 - a) + y * a
export const clamp = (a: number, min = 0, max = 1) =>
	Math.min(max, Math.max(min, a))
export const invlerp = (x: number, y: number, a: number) =>
	clamp((a - x) / (y - x))
export const range = (
	x1: number,
	y1: number,
	x2: number,
	y2: number,
	a: number,
) => Math.round(lerp(x2, y2, invlerp(x1, y1, a)))

const customTwMerge = extendTailwindMerge({
	theme: {
		colors: formatColors(),
		borderRadius: Object.keys(extendedTheme.borderRadius),
	},
	classGroups: {
		'font-size': [
			{
				text: Object.keys(extendedTheme.fontSize),
			},
		],
		animate: [
			{
				animate: Object.keys(extendedTheme.animation),
			},
		],
	},
})

export function cn(...inputs: ClassValue[]) {
	return customTwMerge(clsx(inputs))
}

export function getDomainUrl(request: Request) {
	const host =
		request.headers.get('X-Forwarded-Host') ??
		request.headers.get('host') ??
		new URL(request.url).host
	const protocol = host.includes('localhost') ? 'http' : 'https'
	return `${protocol}://${host}`
}

export function getReferrerRoute(request: Request) {
	// spelling errors and whatever makes this annoyingly inconsistent
	// in my own testing, `referer` returned the right value, but 🤷‍♂️
	const referrer =
		request.headers.get('referer') ??
		request.headers.get('referrer') ??
		request.referrer
	const domain = getDomainUrl(request)
	if (referrer?.startsWith(domain)) {
		return referrer.slice(domain.length)
	} else {
		return '/'
	}
}

/**
 * Merge multiple headers objects into one (uses set so headers are overridden)
 */
export function mergeHeaders(
	...headers: Array<ResponseInit['headers'] | null | undefined>
) {
	const merged = new Headers()
	for (const header of headers) {
		if (!header) continue
		for (const [key, value] of new Headers(header).entries()) {
			merged.set(key, value)
		}
	}
	return merged
}

/**
 * Combine multiple header objects into one (uses append so headers are not overridden)
 */
export function combineHeaders(
	...headers: Array<ResponseInit['headers'] | null | undefined>
) {
	const combined = new Headers()
	for (const header of headers) {
		if (!header) continue
		for (const [key, value] of new Headers(header).entries()) {
			combined.append(key, value)
		}
	}
	return combined
}

/**
 * Combine multiple response init objects into one (uses combineHeaders)
 */
export function combineResponseInits(
	...responseInits: Array<ResponseInit | null | undefined>
) {
	let combined: ResponseInit = {}
	for (const responseInit of responseInits) {
		combined = {
			...responseInit,
			headers: combineHeaders(combined.headers, responseInit?.headers),
		}
	}
	return combined
}

/**
 * Provide a condition and if that condition is falsey, this throws an error
 * with the given message.
 *
 * inspired by invariant from 'tiny-invariant' except will still include the
 * message in production.
 *
 * @example
 * invariant(typeof value === 'string', `value must be a string`)
 *
 * @param condition The condition to check
 * @param message The message to throw (or a callback to generate the message)
 * @param responseInit Additional response init options if a response is thrown
 *
 * @throws {Error} if condition is falsey
 */
export function invariant(
	condition: any,
	message: string | (() => string),
): asserts condition {
	if (!condition) {
		throw new Error(typeof message === 'function' ? message() : message)
	}
}

/**
 * Returns navigation and location while deriving whether the navigation is
 * going to a new page or happening on the current page via a fetcher.
 */
export function useNavigationWithDirection() {
	const navigation = useNavigation()
	const location = useLocation()

	return {
		...navigation,
		direction: navigation.location
			? navigation.location.pathname === location.pathname
				? 'self'
				: 'forward'
			: null,
	} as const
}

/**
 * Provide a condition and if that condition is falsey, this throws a 400
 * Response with the given message.
 *
 * inspired by invariant from 'tiny-invariant'
 *
 * @example
 * invariantResponse(typeof value === 'string', `value must be a string`)
 *
 * @param condition The condition to check
 * @param message The message to throw (or a callback to generate the message)
 * @param responseInit Additional response init options if a response is thrown
 *
 * @throws {Response} if condition is falsey
 */
export function invariantResponse(
	condition: any,
	message: string | (() => string),
	responseInit?: ResponseInit,
): asserts condition {
	if (!condition) {
		throw new Response(typeof message === 'function' ? message() : message, {
			status: 400,
			...responseInit,
		})
	}
}

/**
 * Returns true if the current navigation is submitting the current route's
 * form. Defaults to the current route's form action and method POST.
 *
 * Defaults state to 'non-idle'
 *
 * NOTE: the default formAction will include query params, but the
 * navigation.formAction will not, so don't use the default formAction if you
 * want to know if a form is submitting without specific query params.
 */
export function useIsPending({
	formAction,
	formMethod = 'POST',
	state = 'non-idle',
}: {
	formAction?: string
	formMethod?: 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE'
	state?: 'submitting' | 'loading' | 'non-idle'
} = {}) {
	const contextualFormAction = useFormAction()
	const navigation = useNavigation()
	const isPendingState =
		state === 'non-idle'
			? navigation.state !== 'idle'
			: navigation.state === state
	return (
		isPendingState &&
		navigation.formAction === (formAction ?? contextualFormAction) &&
		navigation.formMethod === formMethod
	)
}

/**
 * This combines useSpinDelay (from https://npm.im/spin-delay) and useIsPending
 * from our own utilities to give you a nice way to show a loading spinner for
 * a minimum amount of time, even if the request finishes right after the delay.
 *
 * This avoids a flash of loading state regardless of how fast or slow the
 * request is.
 */
export function useDelayedIsPending({
	formAction,
	formMethod,
	delay = 400,
	minDuration = 300,
}: Parameters<typeof useIsPending>[0] &
	Parameters<typeof useSpinDelay>[1] = {}) {
	const isPending = useIsPending({ formAction, formMethod })
	const delayedIsPending = useSpinDelay(isPending, {
		delay,
		minDuration,
	})
	return delayedIsPending
}

function callAll<Args extends Array<unknown>>(
	...fns: Array<((...args: Args) => unknown) | undefined>
) {
	return (...args: Args) => fns.forEach(fn => fn?.(...args))
}

/**
 * Use this hook with a button and it will make it so the first click sets a
 * `doubleCheck` state to true, and the second click will actually trigger the
 * `onClick` handler. This allows you to have a button that can be like a
 * "are you sure?" experience for the user before doing destructive operations.
 */
export function useDoubleCheck() {
	const [doubleCheck, setDoubleCheck] = useState({} as Record<string, boolean>)

	function getButtonProps(
		id: string,
		props?: React.ButtonHTMLAttributes<HTMLButtonElement>,
	) {
		const onBlur: React.ButtonHTMLAttributes<HTMLButtonElement>['onBlur'] =
			() => setDoubleCheck({ ...doubleCheck, [id]: false })

		const onClick: React.ButtonHTMLAttributes<HTMLButtonElement>['onClick'] =
			doubleCheck[id]
				? undefined
				: e => {
						e.stopPropagation()
						e.preventDefault()
						setDoubleCheck({ ...doubleCheck, [id]: true })
				  }

		const onKeyUp: React.ButtonHTMLAttributes<HTMLButtonElement>['onKeyUp'] =
			e => {
				if (e.key === 'Escape') {
					setDoubleCheck({})
				}
			}

		return {
			...props,
			onBlur: callAll(onBlur, props?.onBlur),
			onClick: callAll(onClick, props?.onClick),
			onKeyUp: callAll(onKeyUp, props?.onKeyUp),
		}
	}

	return { doubleCheck, getButtonProps }
}

/**
 * Simple debounce implementation
 */
function debounce<Callback extends (...args: Parameters<Callback>) => void>(
	fn: Callback,
	delay: number,
) {
	let timer: ReturnType<typeof setTimeout> | null = null
	return (...args: Parameters<Callback>) => {
		if (timer) clearTimeout(timer)
		timer = setTimeout(() => {
			fn(...args)
		}, delay)
	}
}

/**
 * Debounce a callback function
 */
export function useDebounce<
	Callback extends (...args: Parameters<Callback>) => ReturnType<Callback>,
>(callback: Callback, delay: number) {
	const callbackRef = useRef(callback)
	useEffect(() => {
		callbackRef.current = callback
	})
	return useMemo(
		() =>
			debounce(
				(...args: Parameters<Callback>) => callbackRef.current(...args),
				delay,
			),
		[delay],
	)
}

export async function downloadFile(url: string, retries: number = 0) {
	const MAX_RETRIES = 3
	try {
		const response = await fetch(url)
		if (!response.ok) {
			throw new Error(`Failed to fetch image with status ${response.status}`)
		}
		const contentType = response.headers.get('content-type') ?? 'image/jpg'
		const blob = Buffer.from(await response.arrayBuffer())
		return { contentType, blob }
	} catch (e) {
		if (retries > MAX_RETRIES) throw e
		return downloadFile(url, retries + 1)
	}
}

/**
 * Use this in conjunction with a Model.find* and { raw: true }
 */
export function toLowerCaseKeys<T extends Model>(obj: T) {
	return Object.fromEntries(
		Object.entries(obj).map(([k, v]) => [k.toLowerCase(), v]),
	) as Attributes<T>
}

export function isMobile(userAgent?: string | null) {
	if (!userAgent) {
		return false
	}

	return [
		'Android',
		'webOS',
		'iPhone',
		'iPad',
		'iPod',
		'BlackBerry',
		'Windows Phone',
	].some(toMatchItem => userAgent.includes(toMatchItem))
}

/**
 * https://stackoverflow.com/questions/71826145/show-keyboard-on-input-focus-without-user-action
 *
 * iOS keyboard can only be opened by user interaction + focus event.
 * There are situations in which we need to open keyboard programmatically
 * before other elements are rendered, like when opening an overlay. this function can be used
 * to open the keyboard before the overlay is rendered, and once the overlay is
 * rendered, it's needed to focus to the desired input element, and the keyboard
 * will stay open.
 *
 * This function needs to be called from native events attached to HTML elements. It will
 * not work if called from a "passive" event, or after any event not coming from
 * user interaction (onLoad, onFocus, etc).
 *
 * It's recommended to use it on MouseEvents or TouchEvents.
 */
export function openiOSKeyboard(isMobile = false) {
	if (!document || typeof window === 'undefined' || !isMobile) {
		return
	}

	const input = document.createElement('input')
	input.setAttribute('type', 'text')
	input.setAttribute('style', 'position: fixed; top: -100px; left: -100px;')
	document.body.appendChild(input)
	input.focus()
}

export function useUnload(fn: () => void) {
	const cb = React.useRef(fn)

	React.useEffect(() => {
		const onUnload = cb.current
		window.addEventListener('beforeunload', onUnload)
		return () => {
			window.removeEventListener('beforeunload', onUnload)
		}
	}, [cb])
}

export type Entries<T> = {
	[K in keyof T]: [K, T[K]]
}[keyof T][]

export function groupBy<Data, Key extends keyof Data>(
	items: Data[],
	key: Key | ((item: Data) => string),
) {
	return items.reduce(
		(obj, item) => {
			const groupByKey = typeof key === 'function' ? key(item) : `${item[key]}`
			const existingObj = obj[groupByKey]

			return {
				...obj,
				[groupByKey]: {
					...(existingObj || {}),
					...item,
				},
			}
		},
		{} as Record<string, Data>,
	)
}

export function groupManyBy<Data, Key extends keyof Data>(
	items: Data[],
	key: Key | ((item: Data) => string),
	options: {
		sortBy?: Key
		sortByDesc?: boolean
		sortNullsLast?: boolean
	} = {},
) {
	const sort = options.sortBy ? defaultSort<Data, Key>(options) : undefined
	return items.reduce(
		(obj, item) => {
			const groupByKey = typeof key === 'function' ? key(item) : `${item[key]}`
			const existingArr = obj[groupByKey] || []

			return {
				...obj,
				[groupByKey]: [...existingArr, item].sort(sort),
			}
		},
		{} as Record<string, Data[]>,
	)
}

export function defaultSort<Data, Key extends keyof Data>(options: {
	sortBy?: Key
	sortByDesc?: boolean
	sortNullsLast?: boolean
}) {
	return (a: Data, b: Data) => {
		const A = a[options.sortBy as Key]
		const B = b[options.sortBy as Key]

		if (options.sortNullsLast && A === null) {
			return 1
		}

		if (options.sortNullsLast && B === null) {
			return -1
		}

		if (options.sortByDesc) {
			return A > B ? -1 : 1
		}

		return A > B ? 1 : -1
	}
}

export function groupManyToMany<Data, Key extends keyof Data>(
	items: Data[],
	key: Key | ((item: Data) => string),
	options: {
		sortBy?: Key
		sortByDesc?: boolean
		sortNullsLast?: boolean
	} = {},
) {
	const sort = options.sortBy ? defaultSort<Data, Key>(options) : undefined

	return items.reduce(
		(obj, item) => {
			const groupByKey = typeof key === 'function' ? key(item) : `${item[key]}`
			const existingArr = obj[groupByKey]

			return {
				...obj,
				[groupByKey]: existingArr
					? sort
						? [...existingArr, item].sort(sort)
						: [...existingArr, item]
					: [item],
			}
		},
		{} as Record<string, Data[]>,
	)
}

// group one game to many stats
export function groupOneToMany<
	Data,
	Key extends keyof Data,
	OneModel extends Model,
	ManyModel extends Model,
>(
	items: Data[],
	key: Key | ((item: Data) => string),
	one: {
		model: ModelStatic<OneModel>
		include?: Key[]
	},
	many: {
		key: Key
		model: ModelStatic<ManyModel>
		include?: string[]
	},
	options: {
		sortBy?: Key
		sortByDesc?: boolean
		sortNullsLast?: boolean
	} = {},
) {
	const manyToMany = groupManyToMany(items, key, options)

	return Object.values(manyToMany).reduce((existingItems, items): Data[] => {
		return [
			...existingItems,
			{
				...mapKeys(one.model, items[0], one.include),
				[many.key]: items
					.filter(i => objectHasValues(many.model, i[many.key]))
					.map(i => mapKeys(many.model, i[many.key], many.include)),
			},
		]
	}, [] as Data[])
}

export function useResize(ref: MutableRefObject<any>) {
	const [width, setWidth] = useState()
	const [height, setHeight] = useState()

	useEffect(
		() => {
			if (!ref.current) {
				// we do not initialize the observer unless the ref has
				// been assigned
				return
			}

			// we also instantiate the resizeObserver and we pass
			// the event handler to the constructor
			const resizeObserver = new ResizeObserver(() => {
				if (ref.current.offsetWidth !== width) {
					setWidth(ref.current.offsetWidth)
				}
				if (ref.current.offsetHeight !== height) {
					setHeight(ref.current.offsetHeight)
				}
			})

			// the code in useEffect will be executed when the component
			// has mounted, so we are certain observedDiv.current will contain
			// the div we want to observe
			resizeObserver.observe(ref.current)

			// if useEffect returns a function, it is called right before the
			// component unmounts, so it is the right place to stop observing
			// the div
			return function cleanup() {
				resizeObserver.disconnect()
			}
		},
		// only update the effect if the ref element changed
		[ref, width, height],
	)

	return {
		width,
		height,
	}
}

export const useOnScreen = (
	ref: MutableRefObject<any>,
	{
		rootMargin = '0px',
		onVisibilityChange,
	}: {
		rootMargin?: string
		onVisibilityChange?: (entry: IntersectionObserverEntry) => void
	},
) => {
	const [isVisible, setIsVisible] = useState(false)

	useEffect(() => {
		if (!ref.current) {
			// we do not initialize the observer unless the ref has
			// been assigned
			return
		}

		const observer = new IntersectionObserver(
			([entry]) => {
				setIsVisible(entry.isIntersecting)
				onVisibilityChange?.(entry)
			},
			{
				rootMargin,
			},
		)

		const currentElement = ref?.current

		if (currentElement) {
			observer.observe(currentElement)
		}

		return () => {
			observer.unobserve(currentElement)
		}
	}, [ref, onVisibilityChange, rootMargin])

	return isVisible
}

export const getImperialFeetInches = (n: number | string) => {
	const number = Number(n)

	if (!n || Number.isNaN(number)) {
		return ''
	}

	return `${Math.floor(number / 12)}'${formatNumber(number % 12, {
		minimumFractionDigits: 2,
	})}"`
}

export const datesAreOnSameDay = (first: Date, second: Date) =>
	first.getFullYear() === second.getFullYear() &&
	first.getMonth() === second.getMonth() &&
	first.getDate() === second.getDate()

export function getRelativeDateTime(date?: Date) {
	if (!date) {
		return ''
	}

	if (datesAreOnSameDay(date, new Date())) {
		return 'Today'
	}

	return date.toLocaleDateString('en-us', {
		month: 'short',
		day: 'numeric',
		year: 'numeric',
	})
}

export function makeSeasonId(season_id?: string | null) {
	if (!season_id) {
		return ''
	}

	if (season_id.includes('-')) {
		return `?${SEASON_COMBOBOX_VALUE}=${season_id}`
	}

	// Translate NBA/G League seasons ids from their rollup ids to real season ids
	const id = season_id.split('')

	// startsWith(20) = G League and '5' = G League Showcase
	id[2] = season_id.startsWith('20')
		? new Date().getFullYear() === MOST_RECENT_DRAFT_YEAR // First half of the season go to showcase, second half go to reg season
			? '5'
			: '2'
		: '2'

	return `?${SEASON_COMBOBOX_VALUE}=${id.join('')}`
}

export function makeInitials(name: string, split: string = ' ') {
	return name
		.split(split)
		.map(n => n.substring(0, 1).toUpperCase())
		.join('')
}

/**
 *
 * @param key the key in form data
 * @param value delete an entry where value = the value you pass in
 */
export function deleteEntry({
	key,
	value,
	formData,
}: {
	key: string
	value: string
	formData: URLSearchParams | FormData
}) {
	const data = formData.getAll(key)

	if (!data) {
		return formData
	}

	formData.delete(key)

	data.forEach(entry => {
		if (String(entry) !== value) {
			formData.append(key, String(entry))
		}
	})

	return formData
}
/**
 *
 * @param key the key in form data
 * @param value update an entry where value = the `oldValue` you pass in
 */
export function updateEntry({
	key,
	oldValue,
	value,
	formData,
}: {
	key: string
	oldValue?: string
	value: string
	formData: URLSearchParams | FormData
}) {
	const data = formData.getAll(key)

	if (oldValue && !value) {
		return deleteEntry({ key, value: oldValue, formData })
	}

	if (!oldValue && value) {
		formData.append(key, value)
		return formData
	}

	if (!data) {
		return formData
	}

	formData.delete(key)

	data.forEach(entry => {
		if (String(entry) === oldValue) {
			formData.append(key, value)
		} else {
			formData.append(key, String(entry))
		}
	})

	return formData
}

export function useOptimisticSearch() {
	const [search] = useSearchParams()
	const navigation = useNavigation()

	return new URLSearchParams(
		navigation.location && navigation.state !== 'submitting'
			? navigation.location.search
			: search,
	)
}

export const plural = (count: number, noun: string, suffix = 's') =>
	`${noun}${count !== 1 ? suffix : ''}`
